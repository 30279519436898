import { Plugin } from '@nuxt/types';
import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';

const userConfiguration: LogsInitConfiguration = {
  clientToken: process.env.DATADOG_CLIENT_TOKEN || 'pubedcf55e7d438f366895e3868568350db',
  forwardErrorsToLogs: true,
  sampleRate: 100
};

const pluginInit: Plugin = (context) => {
  // https://docs.datadoghq.com/logs/log_collection/javascript/?tab=eu
  datadogLogs.init(userConfiguration);

  datadogLogs.addLoggerGlobalContext('project', 'brokerprofile-ui');
  datadogLogs.addLoggerGlobalContext('host', window.location.hostname);
  datadogLogs.addLoggerGlobalContext('service', 'browser_log');
  datadogLogs.addLoggerGlobalContext('co.stage', context.$config.STAGE);
  datadogLogs.addLoggerGlobalContext('co.team', 'phoenix');
};

export default pluginInit;
