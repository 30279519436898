import { Plugin } from '@nuxt/types';
import { MetaInfo, ScriptPropertySrcCallback } from 'vue-meta';

interface recaptchaWindow extends Window {
  grecaptcha: {
    enterprise: {
      ready: (callback: () => void) => void;
      execute: (siteKey: string, parameters: { action: string }) => Promise<string>;
    };
  };
}
declare let window: recaptchaWindow;

declare module 'vue/types/vue' {
  interface Vue {
    $grecaptcha: Grecaptcha;
  }
}

declare module '@nuxt/types/app' {
  interface NuxtAppOptions {
    $grecaptcha: Grecaptcha;
  }
}

export class Grecaptcha {
  /** promise resolver to mark nuxt plugin initialization */
  private initializer?: () => void;
  /** promise which marks if nuxt plugin is initialized */
  private readonly $initialized: Promise<void>;

  /** marks recaptcha as initialized */
  initialize(): void {
    if (!window.grecaptcha) {
      console.warn('[recaptcha] recaptcha was not initialized properly');
      return;
    }
    this.initializer?.();
  }

  constructor(private readonly siteKey: string) {
    this.$initialized = new Promise((resolve) => (this.initializer = resolve));
  }

  private ready(): Promise<void> {
    return new Promise<void>((resolve) => window.grecaptcha.enterprise.ready(() => resolve()));
  }

  public async issueRecaptcha(action: string): Promise<{ grecaptchaToken: string; siteKey: string }> {
    await this.$initialized;
    await this.ready();
    const token = await window.grecaptcha.enterprise.execute(this.siteKey, { action });
    return { grecaptchaToken: token, siteKey: this.siteKey };
  }
}

const googleRecaptchaEnterprisePlugin: Plugin = (context, inject) => {
  if (!context.$config.googleRecaptchaSiteKey) {
    return;
  }

  const head = context.app.head as MetaInfo;
  if (!head || !head.script) {
    throw new Error('Cannot initialize Recaptcha');
  }

  const grecaptchaInjectee = new Grecaptcha(context.$config.googleRecaptchaSiteKey);

  const headScriptTag: ScriptPropertySrcCallback = {
    vmid: 'google-recaptcha-enterprise-script',
    src: `https://www.google.com/recaptcha/enterprise.js?render=${context.$config.googleRecaptchaSiteKey}`,
    hid: 'recaptcha',
    callback: () => grecaptchaInjectee.initialize()
  };
  head.script.push(headScriptTag);

  inject('grecaptcha', grecaptchaInjectee);
};

export default googleRecaptchaEnterprisePlugin;
