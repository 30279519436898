import { isFuture } from 'date-fns';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AuthToken } from '~/types/store';
import { CustomerType } from '~/types/types';

@Module({
  name: 'auth',
  namespaced: true,
  stateFactory: true
})
export default class AuthStore extends VuexModule {
  _oAuthToken: AuthToken = undefined;
  _oAuthTokenString: string | undefined = undefined;
  _customerType: CustomerType | undefined | null = null;

  get isLoggedIn(): boolean {
    return !!this._oAuthToken && isFuture(new Date(this._oAuthToken.exp * 1000));
  }

  get globalUserId(): number | undefined {
    return this._oAuthToken?.sub ? Number(this._oAuthToken?.sub) : undefined;
  }

  // authenticated users customer type
  get customerType(): CustomerType | undefined | null {
    return this._customerType;
  }

  get bearerToken(): string | undefined {
    return this._oAuthTokenString ? `Bearer ${this._oAuthTokenString}` : undefined;
  }

  @Mutation
  setOAuthTokenFromCookie(cookies: string): void {
    const oAuthTokenString: string | undefined = getCookieValue('oauth.access.token', cookies);
    const oAuthToken: AuthToken | undefined = oAuthTokenString ? decodeOAuthToken(oAuthTokenString) : undefined;
    const expired = !isFuture((oAuthToken?.exp ?? 0) * 1000);
    if (expired) {
      return;
    }
    this._oAuthToken = oAuthToken;
    this._oAuthTokenString = oAuthTokenString;
  }

  @Mutation
  setCustomerType(customerType: CustomerType | undefined | null): void {
    this._customerType = customerType;
  }
}

function decodeOAuthToken(oAuthTokenString: string): AuthToken {
  if (!oAuthTokenString) return undefined;

  const base64Url = oAuthTokenString.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const test = Buffer.from(base64, 'base64');
  const jsonPayload = decodeURIComponent(
    test
      .toString()
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload) as AuthToken;
}

function getCookieValue(name: string, cookies: string): string | undefined {
  const value = ';' + cookies;
  const parts = value.split(name + '=');
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
}
