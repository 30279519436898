/**
 * Returns the current scroll offset of the element up to the
 * body element.
 * @param element html element offset to be calculated for
 * @returns the offset in pixels
 */
export function getOffsetToBody(element?: Element | null): number {
  function isHtmlElement(element: Element): element is HTMLElement {
    return 'offsetParent' in element && 'offsetTop' in element;
  }

  if (!element || !isHtmlElement(element)) {
    return Infinity;
  }

  if (element.tagName.toLocaleLowerCase() === 'body') {
    return 0;
  }

  const { offsetParent, offsetTop } = element;
  return offsetTop + getOffsetToBody(offsetParent);
}
