<template>
  <div>
    <p class="error-page__headline">
      Ups! Hier haben selbst wir <br />
      nichts passendes gefunden.
    </p>
    <p class="error-page__desc">Die aufgerufene Seite existiert nicht.</p>
    <sd-btn @click="goHome" v-ripple>Zurück zur Startseite</sd-btn>
  </div>
</template>

<script>
export default {
  name: 'Error404',
  mounted() {
    console.error(`404 location: ${location.toString()}`);
  },
  methods: {
    goHome() {
      location.href = location.origin;
    }
  }
};
</script>
