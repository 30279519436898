import { initialiseStores } from '~/utils/accessor';
import { BrokerProfilePageStore } from '~/types/store';

type StoreInitializer = (store: BrokerProfilePageStore) => void;
const initializer: StoreInitializer = (store) => initialiseStores(store);

export const plugins = [initializer];
export * from '~/utils/accessor';

// export const actions = {
//   nuxtServerInit(store: Store<unknown>, context: Context): void {
//   }
// };
