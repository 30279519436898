import { Plugin } from '@nuxt/types';
import onRequest from '~/interceptors/request';
import onResponse from '~/interceptors/response';
import onError from '~/interceptors/error';

const interceptors: Plugin = ({ $axios }) => {
  $axios.onRequest((config) => {
    onRequest(config);
  });

  $axios.onResponse((response) => {
    return onResponse(response);
  });

  $axios.onError((error) => {
    // try to handle graphql errors
    if (error.response?.data?.errors?.length > 0) {
      try {
        const errorMessages: { message: string }[] = error.response?.data.errors;
        const combinedMessage = errorMessages
          .map((errMsg) => errMsg.message)
          .reduce((combined, msg) => [combined, msg].join(', '));
        const postData = error.config.data ? JSON.parse(error.config.data) : {};
        const messageParts = [
          `GraphQL: ${error.message} [${combinedMessage}]`,
          `Operation: ${postData.operationName ?? '?'}`,
          `Variables: ${JSON.stringify(postData.variables)}`
        ];
        error.message = messageParts.join('; ');
      } catch (err) {
        // it's ok to fail, this is just a logging optimization try
      }
    }
    onError(error);
  });
};

export default interceptors;
