








import { namespace, Vue, Component } from 'nuxt-property-decorator';

import navigationUi from '~/components/navigation-ui/navigation-ui';
import '@smartdesign/core/css/main.css';
const pageStore = namespace('page');

@Component({
  components: {
    navigationUi
  }
})
export default class DefaultLayout extends Vue {
  @pageStore.Getter
  readonly isImmonet!: boolean;

  @pageStore.Getter
  readonly isImmowelt!: boolean;

  get mediaUrl() {
    if (this.isImmonet) return 'immonet.de';
    return 'immowelt.org';
  }

  created() {
    if (this.isImmonet) {
      require('@smartdesign/core/css/themes/in/index.css');
    } else {
      require('@smartdesign/core/css/themes/iw/index.css');
    }
  }
}
