import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
@Module({
  name: 'kingofthehill',
  namespaced: true,
  stateFactory: true
})
export default class KingOfTheHillStore extends VuexModule {
  _showBadgeTab = false;

  get showBadgeTab(): boolean {
    return this._showBadgeTab;
  }

  @Mutation
  public toggleShowBadgeTab(show: boolean): void {
    this._showBadgeTab = show;
  }
}
