import { Store } from 'vuex';
import { BrokerData, ContactPerson, ContactPersonPage, EstateObjectListPage } from './types';

export type BrokerProfilePageStore = Store<BrokerData | EstateObjectListPage | ContactPersonPage | ContactPerson>;
export type Tenant = 'immowelt' | 'immonet';
export const MediaIds = {
  ImmoweltDE: 0,
  ImmoweltAT: 114,
  Immonet: 195
} as const;
export type MediaId = typeof MediaIds[keyof typeof MediaIds];
export const isMediaId = (x?: number): x is MediaId =>
  typeof x === 'number' && Object.values<number>(MediaIds).includes(x);
export const getTenantFromMediaID = (x: MediaId): Tenant => (x === MediaIds.Immonet ? 'immonet' : 'immowelt');
// 'AllgemeinerUser': 10,
// 'GrundstuecksSuchender': 11,
// 'Wohnungsmieter': 12,
// 'Wohnungskaeufer': 13,
// 'Hauskaeufer': 14,
// 'Hausmieter': 15,
// 'Kapitalanleger': 16,
// 'AuslandsimmobilienKaeufer': 17,
// 'GewerbeimmobilienSuchender': 18,
// 'WgSuchender': 19,
// 'FewoMieter': 20,
// 'Makler': 50,
// 'EinzelobjektanbieterVerkaeufer': 51,
// 'EinzelobjektanbieterVermieter': 52,
// 'FewoAnbieter': 53
export type UserType =
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '50'
  | '51'
  | '52'
  | '53'
  | undefined;
export type Origin = 'immowelt.de' | 'immowelt.at' | 'immowelt.ch' | 'immonet.de';
export type SocialMediaMetaTagType = { property: string; content: string } | { name: string; content: string };
export type CanonicalTagType = { rel?: string; hreflang?: string; href?: string };
export type FavIconType = { rel: string; type?: string; href: string; sizes?: string; color?: string };
export type ScriptHeader = {
  type?: 'application/ld+json' | 'text/javascript';
  hid?: string;
  src?: string;
  async?: string;
  defer?: boolean;
  callback?: () => void;
  // eslint-disable-next-line no-use-before-define
  json?: StructuredDataType | BreadCrumb | undefined;
  innerHTML?: string;
};
export type StructuredDataType = {
  '@context': string;
  '@type': string;
  name?: string;
  image?: string;
  address: {
    '@type': string;
    streetAddress?: string;
    addressLocality?: string;
    postalCode?: string;
  };
  aggregateRating: {
    '@type': string;
    ratingValue: string;
    bestRating: string;
    worstRating: string;
    ratingCount: string;
  };
};
export type BreadCrumbDataLinkItem = {
  '@id': string;
  name: string;
};
export type BreadCrumbData = {
  '@type'?: string;
  position?: number;
  item?: BreadCrumbDataLinkItem;
};
export type BreadCrumb = {
  '@context': string;
  '@type': string;
  itemListElement: BreadCrumbData[];
};
export type AuthToken =
  | {
      sub: string;
      // eslint-disable-next-line camelcase
      user_name: string;
      scope: string[];
      iss: string;
      exp: number;
      // eslint-disable-next-line camelcase
      creation_offset_date_time: string;
      authorities: string[];
      jti: string;
      tenant: Tenant;
      // eslint-disable-next-line camelcase
      fully_authenticated: boolean;
      // eslint-disable-next-line camelcase
      client_id: string;
    }
  | undefined;

export type GoogleDoubleClickModel = {
  adGroup: string;
  subAdGroup: string;
  immobilienart: number;
  kategorie: string;
  mieteKauf: number;
  geoId: string;
  ort: string;
  ortClean: string;
  pageHasContentAdd: boolean;
  userType: UserType;
  disableAdvertisement: boolean;
  anbieterGuid: string;
  globaluserid: number | undefined;
};

export type BannerData =
  | {
      id: string | undefined;
      sizes: string[];
      divId: string;
    }
  | undefined;

export type BannerConfig = {
  [mediaId in MediaId]: {
    skyscraper: BannerData;
    superbanner: BannerData;
    rectangular: BannerData;
  };
};
