














import { namespace, Vue, Component } from 'nuxt-property-decorator';

import navigationUi from '~/components/navigation-ui/navigation-ui';
import seoBreadcrumb from '~/components/seoBreadcrumb/seoBreadcrumb';
import feedbackWidget from '~/components/feedback-widget/feedback-widget';
import bpAdsSuperBanner from '~/components/ads/ads-superbanner';
import '@smartdesign/core/css/main.css';
const pageStore = namespace('page');

@Component({
  components: {
    navigationUi,
    seoBreadcrumb,
    bpAdsSuperBanner,
    feedbackWidget
  }
})
export default class DefaultLayout extends Vue {
  @pageStore.Getter
  readonly isImmonet!: boolean;

  @pageStore.Getter
  readonly isImmowelt!: boolean;

  get mediaUrl(): string {
    if (this.isImmonet) return 'immonet.de';
    return 'immowelt.org';
  }

  created(): void {
    if (this.isImmonet) {
      require('@smartdesign/core/css/themes/in/index.css');
    } else {
      require('@smartdesign/core/css/themes/iw/index.css');
    }
  }
}
