import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import deepMerge from 'deepmerge';
import {
  BrokerData,
  BasicData,
  BrokerMedia,
  EstateObjectListPage,
  HouseIdea,
  ModelHouse,
  LegalNoticeContext,
  Awards,
  ContactPersonPage,
  ContactPerson,
  ReferenceObject,
  BrokerValuation,
  BrokerSettings,
  Scalars
} from '~/types/types';
@Module({
  name: 'broker',
  namespaced: true,
  stateFactory: true
})
export default class BrokerProfileStore extends VuexModule {
  _brokerData?: BrokerData;
  _brokerMedia?: BrokerMedia;

  get brokerData(): BrokerData | undefined {
    return this._brokerData;
  }

  get globalUserId(): Scalars['String'] | undefined {
    return this._brokerData?.brokerId;
  }

  get basicData(): BasicData | undefined {
    return this._brokerData?.basicData;
  }

  get brokerMedia(): BrokerMedia | undefined {
    return this._brokerMedia;
  }

  get estateList(): EstateObjectListPage | undefined {
    return this._brokerData?.estateList;
  }

  get houseIdeaUrls(): HouseIdea | undefined {
    return this._brokerData?.houseIdeaUrls;
  }

  get modelHouseUrls(): ModelHouse | undefined {
    return this._brokerData?.modelHouseUrls;
  }

  get legalNotice(): LegalNoticeContext | undefined {
    return this._brokerData?.legalNotice;
  }

  get awards(): Awards | undefined {
    return this._brokerData?.awards;
  }

  get contactPersons(): ContactPersonPage | undefined {
    return this._brokerData?.contactPersons;
  }

  get contactPersonPrimary(): ContactPerson | undefined {
    return this._brokerData?.contactPersonPrimary;
  }

  get referenceObjects(): ReferenceObject[] | undefined {
    return this._brokerData?.referenceObjects;
  }

  get brokerValuation(): BrokerValuation | null | undefined {
    return this._brokerData?.brokerValuation;
  }

  get settings(): BrokerSettings | undefined {
    return this._brokerData?.settings;
  }

  get geoUrlPath(): string | null | undefined {
    return this._brokerData?.geoUrlPath;
  }

  // locationId cannot be used without dependency on Geo service or Geo database
  // historic `country` values are not constrained, matching is an approximation for most probable values
  get isBrokerFromGermany(): boolean {
    if (this._brokerData?.basicData.broker?.country) {
      return ['deu', 'de', 'd'].includes(this._brokerData.basicData.broker.country.toLowerCase());
    }
    return true;
  }

  get isBrokerFromAustria(): boolean {
    if (this._brokerData?.basicData.broker?.country) {
      return ['aut', 'at', 'a'].includes(this._brokerData.basicData.broker.country.toLowerCase());
    }
    return false; // default is 'DE'.
  }

  @Mutation
  public setBrokerData(broker: BrokerData): void {
    this._brokerData = broker;
  }

  @Mutation
  public setLogoUrl(url: string | undefined): void {
    if (this._brokerData?.basicData.broker?.logoUrl) {
      this._brokerData.basicData.broker.logoUrl = url;
    }
  }

  @Mutation
  public setBrokerMedia(brokerMedia?: BrokerMedia): void {
    this._brokerMedia = brokerMedia;
  }

  @Mutation
  public aggregateBrokerData(brokerData: BrokerData): void {
    if (!this._brokerData) {
      this._brokerData = brokerData;
    } else {
      this._brokerData = deepMerge(this._brokerData, brokerData);
    }
  }

  @Mutation
  public addEstateObjects(estateObjectListPage: EstateObjectListPage): void {
    if (this._brokerData?.estateList) {
      this._brokerData.estateList.pagination = estateObjectListPage.pagination;

      if (this._brokerData?.estateList?.data && estateObjectListPage.data) {
        this._brokerData.estateList.data.push(...estateObjectListPage.data);
      }
    }
  }

  @Mutation
  public addContactPersons(contactPersonPage: ContactPersonPage): void {
    if (this._brokerData?.contactPersons) {
      this._brokerData.contactPersons.pagination = contactPersonPage.pagination;

      if (this._brokerData?.contactPersons?.data && contactPersonPage.data) {
        this._brokerData.contactPersons.data.push(...contactPersonPage.data);
      }
    }
  }

  @Mutation
  public setPrimaryContactPerson(primaryContactPerson: ContactPerson): void {
    if (this._brokerData) {
      this._brokerData.contactPersonPrimary = primaryContactPerson;
    }
  }

  @Mutation
  public clearEstateList(): void {
    if (this._brokerData?.estateList) {
      if (this._brokerData?.estateList?.data) {
        this._brokerData.estateList.data = [];
      }
    }
  }
}
