/* eslint-disable camelcase */
import { Context, Plugin } from '@nuxt/types';
import thor from '@sl/thor';
import { MediaId, MediaIds } from '~/types/store';
import { BrokerData, EstateObject, EstateObjectListPage } from '~/types/types';

declare module 'vue/types/vue' {
  interface Vue {
    $trackingNew: TrackingNew;
  }
}

declare module '@nuxt/types/app' {
  interface NuxtAppOptions {
    $trackingNew: TrackingNew;
  }
}

type Site = 'IWT' | 'IWT-AT' | 'INT';

type DistributionType = 1 | 2; // 1 = RENT/MIETE, 2 = BUY/KAUF

type ClassifiedItem = {
  list_name: 'agency_detail';
  name: 'classified';
  index: number; // index in the list
  id?: string;
  client_id: string;
  price?: number;
  currency?: string;
  indoor_surface?: string;
  city?: string;
  zip_code?: string;
  estate_type?: undefined; // we don't want to map this back to AVIV classifieds format
  nb_picture: number;
  nb_rooms?: number;
  distribution_type?: DistributionType;
  publication_id: Site;
};

const mapEstateType: { [key: string]: string | undefined } = {
  Wohnungen: 'av_2',
  Häuser: 'av_5',
  Grundstücke: 'av_9',
  'Büro-/Praxisflächen': 'av_7',
  Ladenflächen: 'av_13',
  'Hallen/Industrieflächen': 'av_12',
  'Gewerbe-Grundstücke': 'av_9',
  Renditeobjekte: 'av_11',
  'Gastronomie/Hotels': 'av_4',
  'Land-/Forstwirtschaft': 'av_1',
  'Garagen/Stellplätze': 'av_8',
  'Wohnen auf Zeit': 'av_5',
  Wohngemeinschaften: 'av_2',
  Typenhäuser: 'av_5',
  Musterhäuser: undefined,
  Sonstiges: undefined,
  Unbekannt: undefined
};

const mapSalesTypeToDistributionType = (salesType: string): DistributionType | undefined => {
  if (salesType === 'MIETE') {
    return 1;
  } else if (salesType === 'KAUF') {
    return 2;
  }
};

// we do the opposite in the backend (mapping SOLR/immonet API price to string), but don't want to break it just for tracking or add another key
const mapPrice = (priceString: string): { value?: number; currency?: string } => {
  const valueRegex = /((\d+\.?)+(,\d+)?)/;
  const currencyRegex = /\d\s(€|[A-Z]{3})/;
  const valueMatch = priceString.match(valueRegex);
  const currencyMatch = priceString.match(currencyRegex);
  if (valueMatch?.[1] && currencyMatch?.[1]) {
    const value = parseFloat(valueMatch[1].replace(/\./g, '').replace(',', '.'));
    const currency = currencyMatch?.[1] === '€' ? 'EUR' : currencyMatch?.[1];
    if (!isNaN(value) && currency) {
      return { value, currency };
    }
  }
  return {};
};

const mapEstateObjectToClassifiedItem = ({
  estateObject,
  index,
  globalUserId,
  site
}: {
  estateObject: EstateObject;
  index: number;
  globalUserId: string;
  site: Site;
}): ClassifiedItem => {
  // ?? undefined to avoid null from Maybe<> type
  const { value, currency } = mapPrice(estateObject.priceValue);
  const distribution_type = estateObject.salesType === 'MIETE' ? 1 : estateObject.salesType === 'KAUF' ? 2 : undefined;
  return {
    list_name: 'agency_detail',
    name: 'classified',
    index,
    id: estateObject.globalObjectKey ?? undefined,
    client_id: globalUserId,
    price: value,
    currency,
    indoor_surface: String(estateObject.area) ?? undefined,
    city: estateObject.city ?? undefined,
    zip_code: estateObject.zip ?? undefined,
    nb_picture: estateObject.imageCount,
    nb_rooms: estateObject.rooms ?? undefined,
    distribution_type,
    publication_id: site
  };
};

type EventKey = 'page_view' | 'view_item_list';
type LeadAgencyNumberEventKey = 'lead_agency_phone_display' | 'lead_agency_fax_display';
type LeadAgencyFormEventKey = 'lead_agency_form_opened' | 'lead_agency_form_submit' | 'lead_agency_form_confirm';
type ClassifiedEventKey = 'select_item' | 'add_to_wishlist';

type User = {
  status: 'logged' | 'unlogged';
  uid: string; // globalUserId
};

type AvEnvironment = 'staging' | 'preprod' | 'prod';

type PageViewEvent = {
  av_user: User;
  av_city?: string;
  av_zip_code?: string;
  av_pagetitle: 'agency_detail';
  av_url_path: string; // path or whole URL
  av_category: 'find_pro';
  av_environment: AvEnvironment;
  av_language: 'de';
  av_platform: 'web';
  av_site: Site;
};

type ClassifiedEvent = {
  av_items?: ClassifiedItem[];
  av_distribution_type_global?: DistributionType;
  av_estate_type_global?: string;
};

const project = ['WantsToSell', 'WantsToRent', 'WantsToBuy', 'estimate'] as const;
type Project = typeof project[number];
const contactReasonToProject: { [key: string]: Project } = {
  selling: 'WantsToSell',
  letting: 'WantsToRent',
  seeking: 'WantsToBuy',
  'value-estimation': 'estimate'
};

type LeadItems = {
  name: 'agency';
  client_id: string; // globalUserId
  client_type: 'pro';
  zip_code?: string;
}[];

type LeadAgencyFormEvent = {
  av_items: LeadItems;
  av_user: { project?: Project };
  av_zone: 'contact_card' | 'bottom' | 'fixed-cta-mobile-buttons';
  av_category: 'find_pro';
};

type LeadAgencyNumberDisplayEvent = {
  av_items: LeadItems;
  av_zone: 'contact_card' | 'bottom' | 'modal_contact_form' | 'agent_card';
};

class TrackingNew {
  private globalUserId: string = this.context.store.getters['broker/brokerData'].brokerId;
  private zipCode: string = this.context.store.getters['broker/brokerData'].basicData?.broker?.zipCode;
  private site: Site;

  constructor(private readonly context: Context) {
    this.site = this.mediaIdToSite(this.context.store.getters['page/mediaId']);
  }

  private mediaIdToSite = (x: MediaId): Site => {
    switch (x) {
      case MediaIds.ImmoweltDE:
        return 'IWT';
      case MediaIds.ImmoweltAT:
        return 'IWT-AT';
      case MediaIds.Immonet:
        return 'INT';
    }
  };

  private createBaseEvent(): PageViewEvent {
    const broker = this.context.store.getters['broker/brokerData'] as BrokerData;
    return {
      av_user: {
        status: this.context.store.getters['auth/isLoggedIn'] ? 'logged' : 'unlogged',
        uid: this.globalUserId
      },
      av_city: broker.basicData?.broker?.city,
      av_zip_code: broker.basicData?.broker?.zipCode,
      av_pagetitle: 'agency_detail',
      av_url_path: window.location.pathname,
      av_category: 'find_pro',
      av_environment: (
        {
          dev: 'staging',
          preview: 'preprod',
          live: 'prod'
        } as { [key: string]: AvEnvironment }
      )[this.context.$config.STAGE],
      av_language: 'de',
      av_platform: 'web',
      av_site: this.site
    };
  }

  private sendEvent(
    key: EventKey | LeadAgencyFormEventKey | LeadAgencyNumberEventKey | ClassifiedEventKey | 'outlink',
    event: ClassifiedEvent | LeadAgencyFormEvent | LeadAgencyNumberDisplayEvent
  ) {
    // TODO dataLayer persists values between events, so we need to reset them
    // feedback from QA: not wanted/works differently?
    // thor.pushVariable({
    //   av_user: null,
    //   av_city: null,
    //   av_zip_code: null,
    //   av_pagetitle: null,
    //   av_url_path: null,
    //   av_category: null,
    //   av_environment: null,
    //   av_language: null,
    //   av_platform: null,
    //   av_site: null,
    //   av_items: null,
    //   av_zone: null,
    //   av_estate_type_global: null,
    //   av_distribution_type_global: null
    // });
    thor.pushEvent(key, event);
  }

  trackPageView = () => {
    const baseEvent = this.createBaseEvent();
    const estateList = this.context.store.getters['broker/estateList'] as EstateObjectListPage;
    const isDesktop = this.context.$device.isDesktop;
    const displayedEstateObjects = isDesktop ? 6 : 3; // defined in `showMoreEstateObjects` mutation
    const event: ClassifiedEvent = {
      ...baseEvent,
      av_items: estateList.data?.slice(0, displayedEstateObjects).map((estateObject, index) =>
        mapEstateObjectToClassifiedItem({
          estateObject,
          index,
          globalUserId: baseEvent.av_user.uid,
          site: baseEvent.av_site
        })
      )
    };
    // TODO page_view is not sent initially, maybe due to async loading of libraries/wrong implementation in thor for pending events?
    setTimeout(() => {
      this.sendEvent('page_view', event);
    }, 5000);
  };

  trackEstateListShowMore(newlyVisibleEstateGoks: Array<EstateObject['globalObjectKey']>) {
    const estateList = this.context.store.getters['broker/estateList'] as EstateObjectListPage;
    if (!estateList.data) return;
    const items: ClassifiedItem[] = [];
    for (let i = 0; i < estateList.data?.length; i++) {
      const estateObject = estateList.data[i];
      if (newlyVisibleEstateGoks.includes(estateObject.globalObjectKey)) {
        items.push(
          mapEstateObjectToClassifiedItem({
            estateObject,
            index: i,
            globalUserId: this.globalUserId,
            site: this.site
          })
        );
      }
    }
    const event: ClassifiedEvent = {
      av_items: items
    };
    this.sendEvent('view_item_list', event);
  }

  trackEstate(key: ClassifiedEventKey, globalObjectKey: EstateObject['globalObjectKey']) {
    const estateList = this.context.store.getters['broker/estateList'] as EstateObjectListPage;
    const index = estateList.data?.findIndex((estate) => estate.globalObjectKey === globalObjectKey) ?? -1;
    const estateObject = estateList.data?.[index];
    if (!estateObject) return;

    const event: ClassifiedEvent = {
      av_items: [
        mapEstateObjectToClassifiedItem({
          estateObject,
          index,
          globalUserId: this.globalUserId,
          site: this.site
        })
      ],
      av_distribution_type_global: mapSalesTypeToDistributionType(estateObject.salesType),
      av_estate_type_global: mapEstateType[estateObject.estateType]
    };
    this.sendEvent(key, event);
  }

  trackLeadAgencyForm(key: LeadAgencyFormEventKey, zone: LeadAgencyFormEvent['av_zone'], contactReason?: string) {
    const items: LeadItems = [
      {
        name: 'agency',
        client_id: this.globalUserId,
        client_type: 'pro',
        zip_code: this.zipCode
      }
    ];
    const event: LeadAgencyFormEvent = {
      av_items: items,
      av_user: {
        project: ['lead_agency_form_submit', 'lead_agency_form_confirm'].includes(key)
          ? contactReasonToProject[contactReason as keyof typeof contactReasonToProject]
          : undefined
      },
      av_zone: zone,
      av_category: 'find_pro'
    };
    this.sendEvent(key, event);
  }

  trackLeadNumberDisplay = (key: LeadAgencyNumberEventKey, zone: LeadAgencyNumberDisplayEvent['av_zone']) => {
    const items: LeadItems = [
      {
        name: 'agency',
        client_id: this.globalUserId,
        client_type: 'pro',
        zip_code: this.zipCode
      }
    ];
    const event: LeadAgencyNumberDisplayEvent = {
      av_items: items,
      av_zone: zone
    };
    this.sendEvent(key, event);
  };

  trackIntermediaryWebsite = (linkUrl: string) => {
    const event = {
      av_category: 'find_pro',
      outbound: 'true',
      link_url: linkUrl
    };
    this.sendEvent('outlink', event as Record<string, string>);
  };
}

const trackingNew: Plugin = (context: Context, inject) => {
  if (process.client) {
    inject('trackingNew', new TrackingNew(context));
    if (context.$config.STAGE === 'dev') {
      thor.onConsent();
    }
    const userConsentInterval = setInterval(() => {
      const needUserInteraction = (window as any).UC_UI?.isConsentRequired();
      if (needUserInteraction === false) {
        clearInterval(userConsentInterval);
        thor.onConsent();
      }
    }, 1000);
  }
};

export default trackingNew;
