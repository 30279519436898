import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ec42e6c6 = () => interopDefault(import('../src/pages/legacyBrowser.vue' /* webpackChunkName: "pages/legacyBrowser" */))
const _b554d766 = () => interopDefault(import('../src/pages/broker/broker.vue' /* webpackChunkName: "pages/broker/broker" */))
const _7d188260 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/legacyBrowser",
    component: _ec42e6c6,
    pathToRegexpOptions: {"strict":true},
    name: "legacyBrowser"
  }, {
    path: "/broker/broker",
    component: _b554d766,
    pathToRegexpOptions: {"strict":true},
    name: "broker-broker"
  }, {
    path: "/",
    component: _7d188260,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/beta/profil/:broker",
    component: _b554d766,
    name: "beta"
  }, {
    path: "/profil/legacy-browser",
    component: _ec42e6c6,
    name: "profil-legacy-browser"
  }, {
    path: "/anbieter/legacy-browser",
    component: _ec42e6c6,
    name: "anbieter-legacy-browser"
  }, {
    path: "/profil/:broker",
    component: _b554d766,
    name: "profil"
  }, {
    path: "/anbieter/:broker",
    component: _b554d766,
    name: "anbieter"
  }, {
    path: "/:broker",
    component: _b554d766,
    name: "broker"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
