import { Vue, Component, namespace, Prop } from 'nuxt-property-decorator';
import { MediaId } from '~/types/store';
import navigationWrapper from '~/components/navigation-wrapper/navigation-wrapper';
const pageStore = namespace('page');

@Component<NavigationUi>({
  components: {
    navigationWrapper
  },
  head() {
    return {
      script: [
        {
          src: `${this.mediaIdDependantPath}/v1/${this.navigationType}/${this.mediaId}/navigation.js`,
          hid: 'navigation-ui',
          async: true
        }
      ]
    };
  }
})
export default class NavigationUi extends Vue {
  @pageStore.Getter readonly mediaId!: MediaId;

  @Prop({ type: String, default: undefined }) readonly highlight: string | undefined;
  @Prop({ type: Boolean, default: true }) readonly smallSticky: boolean | undefined;
  @Prop({ type: Boolean, default: true }) readonly bigSticky: boolean | undefined;
  @Prop(String) readonly mediaUrl: string | undefined;
  @Prop({ type: String, default: 'FULL' }) readonly navigationType: string | undefined;
  @Prop({ type: Boolean, default: true }) readonly showFooter: boolean | undefined;

  mounted(): void {
    console.log('mounted', this.mediaIdDependantPath, this.navigationType, this.mediaId);
    console.log(`${this.mediaIdDependantPath}/v1/${this.navigationType}/${this.mediaId}/navigation.js`);
  }

  get mediaIdDependantPath(): string {
    let stage = this.$config.STAGE;

    if (stage === 'dev') {
      stage = '-dev';
    }

    if (stage === 'preview') {
      stage = '-preview';
    }

    if (stage === 'live') {
      stage = '';
    }

    return `https://navigation${stage}.${this.mediaUrl}`;
  }
}
