import { Context } from '@nuxt/types';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { getModule } from 'vuex-module-decorators';
import { Device } from '@nuxtjs/device';
import BrokerProfileStore from '~/store/broker';
import ComponentStore from '~/store/component';
import PageStore from '~/store/page';
import AuthStore from '~/store/auth';
import KingOfTheHillStore from '~/store/kingofthehill';

import { BrokerProfilePageStore } from '~/types/store';

/* eslint-disable import/no-mutable-exports */
let $nuxt: Context;
let $axios: NuxtAxiosInstance;
let $device: Device;
let brokerProfileStore: BrokerProfileStore;
let componentStore: ComponentStore;
let pageStore: PageStore;
let authStore: AuthStore;
let kingOfTheHillStore: KingOfTheHillStore;

export function initializeNuxt(nuxtInstance: Context): void {
  $nuxt = nuxtInstance;
}

export function initializeAxios(axiosInstance: NuxtAxiosInstance): void {
  $axios = axiosInstance;
}
export function initializeDevices(deviceInstance: Device): void {
  $device = deviceInstance;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function initialiseStores(store: BrokerProfilePageStore) {
  brokerProfileStore = getModule(BrokerProfileStore, store);
  componentStore = getModule(ComponentStore, store);
  pageStore = getModule(PageStore, store);
  authStore = getModule(AuthStore, store);
  kingOfTheHillStore = getModule(KingOfTheHillStore, store);

  return {
    brokerProfileStore,
    componentStore,
    authStore,
    pageStore,
    $device,
    kingOfTheHillStore
  };
}

export { $nuxt, $axios, brokerProfileStore, componentStore, authStore, pageStore, $device, kingOfTheHillStore };
