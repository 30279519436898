import { Vue, Component, namespace } from 'nuxt-property-decorator';
const pageStore = namespace('page');

// eslint-disable-next-line no-use-before-define
@Component<FeedbackWidget>({
  head() {
    return {
      script: [
        {
          src: `https://${this.stage}immowelt.de/feedback/widget/feedback-webcomponent.min.js`,
          hid: 'feedback-widget',
          async: true
        }
      ]
    };
  }
})
export default class FeedbackWidget extends Vue {
  @pageStore.Getter readonly isImmowelt!: boolean;

  get stage(): string {
    let stage = this.$config.STAGE;

    if (stage === 'dev') {
      stage = 'dev.';
    }

    if (stage === 'preview') {
      stage = 'preview.';
    }

    if (stage === 'live') {
      stage = '';
    }

    return stage;
  }
}
