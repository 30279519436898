import { getOffsetToBody } from '~/utils/offset-calculate';

const delay = (delayMs = 50) => new Promise((resolve) => setTimeout(resolve, delayMs));

const findEl = (hash = '', tryCount = 0) => {
  if (!hash) {
    return Promise.resolve(null);
  }
  const element = document.querySelector(hash);
  if (element) {
    return Promise.resolve(element);
  }
  if (tryCount > 50) {
    return Promise.resolve(null);
  }
  return delay(50).then(() => findEl(hash, tryCount + 1));
};
/**
 * @Dokumentation: https://nuxtjs.org/api/configuration-router#scrollbehavior
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const defaultScrollingBehaviour = async (to, from) => {
  if (to.hash) {
    const el = await findEl(to.hash);

    if (el) {
      const offsetTop = getOffsetToBody(el);
      const newYPosition = offsetTop - navbarTopScrollOffset;
      return { y: newYPosition, behavior: 'smooth' };
    }
  }

  /** if we clear the hash from inside the tabbar, we do not want to scroll to the top of the page */
  if (from.hash) {
    return false;
  }

  return { x: 0, y: 0 };
};

export const navbarTopScrollOffset = 104;

export default defaultScrollingBehaviour;
