import { Vue, Component, namespace } from 'nuxt-property-decorator';
import { sdBreadcrumbItem, sdBreadcrumb } from '@smartdesign/vue/components/breadcrumb';
import { $nuxt } from '~/store';
import { BasicData } from '~/types/types';
const pageStore = namespace('page');
const brokerStore = namespace('broker');
@Component({
  components: {
    sdBreadcrumb,
    sdBreadcrumbItem
  }
})
export default class SeoBreadcrumb extends Vue {
  @pageStore.Getter readonly isImmonet!: boolean;
  @brokerStore.Getter readonly geoUrlPath?: string;
  @brokerStore.Getter readonly basicData!: BasicData;

  get brokerDirectory(): string {
    if (this.isImmonet) return `${$nuxt.$config.DOMAIN_IN}.de/immobilienmakler.html`;
    if (this.geoUrlPath) {
      return `${$nuxt.$config.DOMAIN_IW}.de/makler${this.geoUrlPath}`;
    }
    return `${$nuxt.$config.DOMAIN_IW}.de/makler/suche`;
  }

  get home(): string {
    if (this.isImmonet) return `${$nuxt.$config.DOMAIN_IN}.de`;
    return `${$nuxt.$config.DOMAIN_IW}.de`;
  }

  get anbieter(): string {
    return this.basicData?.broker?.companyName ?? 'Anbieter';
  }

  get suche(): string {
    return `${$nuxt.$config.MAKLERSUCHE_URL}`;
  }
}
