<template>
  <div>
    <p class="error-page__headline">
      Es tut uns leid, <br />
      aber etwas stimmt nicht.
    </p>
    <p class="error-page__desc">
      Beim Laden dieser Seite ist leider <br />
      ein unbekannter Fehler aufgetreten.
    </p>
    <sd-btn @click="refresh" v-ripple has-left-icon="redo">Seite neu Laden</sd-btn>
  </div>
</template>

<script>
export default {
  name: 'Error500',
  methods: {
    refresh() {
      location.reload();
    }
  }
};
</script>
