import { BannerConfig, GoogleDoubleClickModel, MediaId, UserType } from '../types/store';
import { CustomerType } from '~/types/types';

let googleValues: GoogleDoubleClickModel;

const setTargeting = (slot: googletag.Slot | null | undefined): void => {
  if (!slot || googleValues === undefined) return;
  if (window.testsystem) slot.setTargeting('IMMO_TESTSYSTEM', window.testsystem);
  if (googleValues.adGroup) slot.setTargeting('IMMO_ADGROUP', googleValues.adGroup);
  if (googleValues.subAdGroup) slot.setTargeting('IMMO_SUBADGROUP', googleValues.subAdGroup);
  if (googleValues.immobilienart) slot.setTargeting('IMMO_ART', googleValues.immobilienart.toString());
  if (googleValues.mieteKauf) slot.setTargeting('IMMO_MK', googleValues.mieteKauf.toString());
  if (googleValues.userType) slot.setTargeting('IMMO_NT', googleValues.userType.toString());
  if (googleValues.geoId) slot.setTargeting('IMMO_GEOID', googleValues.geoId);
  if (googleValues.ort) slot.setTargeting('IMMO_ORT', googleValues.ort);
  if (googleValues.ortClean) slot.setTargeting('IMMO_ORTCLEAN', googleValues.ortClean);
  if (googleValues.kategorie) slot.setTargeting('IMMO_KTG', googleValues.kategorie);
  if (googleValues.anbieterGuid) slot.setTargeting('IMMO_ANBIETER', googleValues.anbieterGuid);
  if (googleValues.globaluserid) slot.setTargeting('IMMO_GLOBALUSERID', googleValues.globaluserid.toString());
};

const getUserType = (customerType: CustomerType | undefined | null): UserType => {
  if (customerType?.contractType === 'PARTNER') {
    return '50';
  }
  return '10';
};

const googleDoubleClickValues = (
  mediaId: MediaId,
  customerType: CustomerType | undefined | null,
  globalUserId: number | undefined
): GoogleDoubleClickModel => {
  const userTypeFromCustomerType = getUserType(customerType);
  const { ort, geoId } = getOrtAndGeoID(mediaId);

  const googleDoubleClickModel: GoogleDoubleClickModel = {
    adGroup: 'Anbieterprofil',
    subAdGroup: '',
    immobilienart: 0,
    kategorie: '',
    mieteKauf: 0,
    geoId,
    ort,
    ortClean: ort,
    userType: userTypeFromCustomerType,
    disableAdvertisement: false,
    pageHasContentAdd: false,
    anbieterGuid: '',
    globaluserid: globalUserId
  };

  return googleDoubleClickModel;
};

const getOrtAndGeoID = (mediaId: MediaId): { ort: string; geoId: string } => {
  let ort = 'Deutschland';
  let geoId = '108';
  switch (mediaId) {
    case 114:
      ort = 'Österreich';
      geoId = '103';
      break;
  }
  return { ort, geoId };
};

const defineSlot = (
  adUnitPath: string | undefined,
  size: string | googletag.SingleSizeArray | googletag.MultiSize | undefined,
  // eslint-disable-next-line camelcase
  ad_div: string | undefined,
  mapping: googletag.SizeMappingArray | undefined
): googletag.Slot | null | undefined => {
  if (isUndefined(window.googletag)) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let slot = window.googletag.defineSlot(adUnitPath ?? '', size as any, ad_div);
  if (isUndefined(slot) || !isFunction(slot.defineSizeMapping)) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slot = slot.defineSizeMapping(mapping as any);
  if (isUndefined(slot) || !isFunction(slot.addService)) {
    return null;
  }

  slot = slot.addService(window.googletag.pubads());
  if (isUndefined(slot) || !isFunction(slot.setCollapseEmptyDiv)) {
    return null;
  }

  return slot.setCollapseEmptyDiv(true);
};

const isUndefined = (slot: unknown): boolean => typeof slot === 'undefined';
const isFunction = (fn: unknown): boolean => typeof fn === 'function';

const bannerConfig: BannerConfig = {
  114: {
    skyscraper: {
      id: '/46859844/Immowelt.at_MasterPage_Skyscraper',
      sizes: ['120x600', '160x600', '200x600', '300x600'],
      divId: 'div-gpt-ad-1450863706069-3'
    },
    superbanner: {
      id: '/46859844/Immowelt.at_MasterPage_Superbanner',
      sizes: ['728x90'],
      divId: 'div-gpt-ad-1450863706069-4'
    },
    rectangular: {
      id: '/46859844/Immowelt.at_Expose_MediumRectangle',
      sizes: ['300x250'],
      divId: 'div-gpt-ad-1450863706069-5'
    }
  },
  0: {
    skyscraper: {
      id: '/46859844/Immowelt.de_MasterPage_Skyscraper',
      sizes: ['120x600', '160x600', '200x600', '300x600'],
      divId: 'div-gpt-ad-1426592241524-1'
    },
    superbanner: {
      id: '/46859844/Immowelt.de_MasterPage_Superbanner',
      sizes: ['728x90'],
      divId: 'div-gpt-ad-1426592241524-0'
    },
    rectangular: {
      id: '/46859844/Immowelt.de_Expose_MediumRectangle',
      sizes: ['300x250'],
      divId: 'div-gpt-ad-1426592241524-2'
    }
  },
  195: {
    skyscraper: undefined,
    superbanner: undefined,
    rectangular: undefined
  }
};

export function initAds(
  mediaId: MediaId,
  customerType: CustomerType | undefined | null,
  globalUserId: number | undefined
): void {
  const breakPointWide = [1770, 0];
  const breakPointL = [1024, 0];

  const mappingSkyscraper = window.googletag
    .sizeMapping()
    .addSize([0, 0], [])
    .addSize(breakPointWide, [
      [120, 600],
      [160, 600],
      [200, 600],
      [300, 600]
    ])
    .build();
  const mappingSuperbanner = window.googletag.sizeMapping().addSize([0, 0], []).addSize(breakPointL, [728, 90]).build();

  const mappingRectangular = window.googletag
    .sizeMapping()
    .addSize([0, 0], [])
    .addSize(breakPointL, [300, 250])
    .addSize(breakPointWide, [])
    .build();

  googleValues = googleDoubleClickValues(mediaId, customerType, globalUserId);

  const banners = bannerConfig[mediaId];

  if (banners) {
    setTargeting(
      defineSlot(banners.superbanner?.id, banners.superbanner?.sizes, banners.superbanner?.divId, mappingSuperbanner)
    );
    setTargeting(
      defineSlot(banners.skyscraper?.id, banners.skyscraper?.sizes, banners.skyscraper?.divId, mappingSkyscraper)
    );
    setTargeting(
      defineSlot(banners.rectangular?.id, banners.rectangular?.sizes, banners.rectangular?.divId, mappingRectangular)
    );
  }
}
