/* eslint-disable camelcase */
import { Context, Plugin } from '@nuxt/types';
import { TabbarTitle } from '~/pages/broker/broker';
import { MediaIds } from '~/types/store';
import {
  TabNaviEventLabel,
  MediaId,
  BaseUtagPageViewElement,
  BrokerProfilePageView,
  LegalNoticePageView,
  ContactPersonPhoneNumberEventClick,
  ContactRequestPageView,
  ContactRequestSuccessPageView,
  Gender,
  UserGroup,
  EventClick,
  EventLabel,
  ObjectInfoElement,
  EnhImpressionPageViewElement
} from '~/types/tealium-events';
import { CustomerType } from '~/types/types';

declare module 'vue/types/vue' {
  interface Vue {
    $track: BrokerProfileTracking;
  }
}

declare module '@nuxt/types/app' {
  interface NuxtAppOptions {
    $track: BrokerProfileTracking;
  }
}

/**
 * Convenience Tracking Component based on https://wiki.iw.de/display/ONMAR/Tracking+-+Anbieterprofil
 */
export class BrokerProfileTracking {
  trackedGoks: string[] = [];

  /** marks utag as initialized */
  constructor(private readonly context: Context) {}

  private isImmonet(): boolean {
    return this.getMedienId() === '195';
  }

  private getMedienId(): MediaId {
    return `${this.context.store.getters['page/mediaId']}` as MediaId;
  }

  private getBrokerGlobalUserId(): string {
    return `${this.context.store.getters['broker/globalUserId']}`;
  }

  private getCurrentSeconds(): number {
    return Math.floor(Date.now() / 1000);
  }

  private getBrokerLocationId(): string {
    return String(this.context.store.getters['broker/basicData']?.broker?.locationId ?? '');
  }

  private getBrokerAddressData(): ObjectInfoElement {
    const { country, city, zipCode } = (this.context.store.getters['broker/basicData']?.broker ?? {}) as {
      country: string;
      city: string;
      zipCode: string;
    };
    return {
      object_city: [city ?? ''],
      object_state: [country ?? ''],
      object_zip: [zipCode ?? '']
    };
  }

  private getBaseUtagPageViewFields(): BaseUtagPageViewElement {
    const mediaId = this.getMedienId();
    const isLoggedIn: boolean = this.context.store.getters['auth/isLoggedIn'];
    type CustomerRegistrationType = 'private' | 'commercial' | '';
    const customerTypeData: CustomerType = this.context.store.getters['auth/customerType'];
    const customerType = (
      isLoggedIn ? customerTypeData?.registrationType?.toLocaleLowerCase() ?? '' : ''
    ) as CustomerRegistrationType;
    return {
      app_medienid: mediaId,
      app_server: 'brokerprofile',
      app_time: this.getCurrentSeconds(),
      app_version: this.context.$config.version,
      customer_is_domestic: '0',
      customer_profile: 'kein profil',
      customer_type: customerType,
      object_locationid: this.getBrokerLocationId(),
      broker_id: this.getBrokerGlobalUserId(),
      broker_guid: this.getBrokerGlobalUserId()
    };
  }

  private getEnhancedImpressionAndObjectGokFields(
    estateGlobalObjectKeys: (string | null)[],
    startIndex = 0
    // eslint-disable-next-line camelcase
  ): EnhImpressionPageViewElement {
    const filteredGoks = estateGlobalObjectKeys.filter((gok) => gok === null || !this.trackedGoks.includes(gok));
    function gokIsNotNull(gok: string | null): gok is string {
      return gok !== null;
    }
    this.trackedGoks.push(...filteredGoks.filter<string>(gokIsNotNull));
    if (filteredGoks.includes(null)) {
      // immonet has no gok
      return {
        enh_impression_id: filteredGoks.map(() => '500'),
        enh_impression_list: filteredGoks.map(() => 'Liste_Anbieterprofil'),
        enh_impression_name: filteredGoks.map(() => 'kontakt'),
        enh_impression_position: filteredGoks.map((_id, idx) => idx + 1 + startIndex),
        enh_impression_price: filteredGoks.map(() => 0),
        enh_impression_variant: filteredGoks.map(() => 'kontakt')
      };
    }
    return {
      enh_impression_id: filteredGoks.map(() => '500'),
      enh_impression_list: filteredGoks.map(() => 'Liste_Anbieterprofil'),
      enh_impression_name: filteredGoks.map(() => 'kontakt'),
      enh_impression_position: filteredGoks.map((_id, idx) => idx + 1 + startIndex),
      enh_impression_price: filteredGoks.map(() => 0),
      enh_impression_variant: filteredGoks.map(() => 'kontakt'),
      object_gok: filteredGoks.filter(Boolean).map((gok) => String(gok).toLocaleLowerCase())
    };
  }

  async viewBrokerProfilePage(estateGlobalObjectKeys: (string | null)[]): Promise<void> {
    const pageView: BrokerProfilePageView = {
      ...this.getBaseUtagPageViewFields(),
      ...this.getEnhancedImpressionAndObjectGokFields(estateGlobalObjectKeys),
      hotjar_activated: true,
      page_name: this.isImmonet() ? '/anbieter/' : '/profil/',
      page_type: 'productdetailpage',
      product_id: ['504'],
      product_name: ['kontakt_anbieter'],
      product_quantity: [1],
      product_variant: ['kontakt_anbieter']
    };
    await this.context.$universalTag.view(pageView, 'main');
  }

  async viewLegalNoticePage(): Promise<void> {
    const pageView: LegalNoticePageView = {
      ...this.getBaseUtagPageViewFields(),
      page_name: this.isImmonet() ? '/anbieter/impressum' : '/profil/impressum'
    };
    await this.context.$universalTag.view(pageView, 'legal-notice');
  }

  async viewContactRequestPage(): Promise<void> {
    const pageView: ContactRequestPageView = {
      ...this.getBaseUtagPageViewFields(),
      page_name: this.isImmonet() ? '/anbieter/contact' : '/profil/contact'
    };
    await this.context.$universalTag.view(pageView, 'contact');
  }

  async viewContactRequestSuccessPage({
    salutation,
    contactReason
  }: {
    salutation: string;
    contactReason: string;
  }): Promise<void> {
    const customerGenderMap: { [key: string]: Gender } = {
      Mr: 'maennlich',
      Mrs: 'weiblich',
      company: 'firma',
      none: 'keine Angabe'
    };
    const customerGender = customerGenderMap[salutation] ?? 'keine Angabe';
    const objectUserGroup = contactReason as UserGroup;

    const pageView: ContactRequestSuccessPageView = {
      ...this.getBaseUtagPageViewFields(),
      ...this.getBrokerAddressData(),
      page_name: this.isImmonet() ? '/anbieter/thankyoupage' : '/profil/thankyoupage',
      page_type: 'thankyoupage',
      customer_gender: customerGender,
      object_usergroup: [objectUserGroup],
      product_id: ['504'],
      product_name: ['kontakt_anbieter'],
      product_order_id: `${this.isImmonet() ? 'IN' : 'IW'}_${this.getCurrentSeconds()}_${this.getBrokerGlobalUserId()}`,
      product_quantity: [1],
      product_variant: ['email']
    };
    // set `true` as we really want to track every successful contact request!
    await this.context.$universalTag.view(pageView, 'thankyoupage-email', true);
  }

  async clickStickyContactModuleContactPersonPhoneNumber(): Promise<void> {
    const eventClick: ContactPersonPhoneNumberEventClick = {
      ...this.getBaseUtagPageViewFields(),
      ...this.getBrokerAddressData(),
      page_name: this.isImmonet() ? '/anbieter/thankyoupage' : '/profil/thankyoupage',
      page_type: 'thankyoupage',
      product_id: ['504'],
      product_name: ['kontakt_anbieter'],
      product_order_id: `${this.isImmonet() ? 'IN' : 'IW'}_${this.getCurrentSeconds()}_${this.getBrokerGlobalUserId()}`,
      product_quantity: [1],
      product_variant: ['phone'],
      event_action: 'click',
      event_category: 'anbieterprofil',
      event_label: 'kontakt_phone-sticky'
    };
    const wasSent = await this.context.$universalTag.event(eventClick, 'thankyoupage-phone');
    if (wasSent) {
      this.context.$universalTag.markEventAsSent<EventLabel>('kontakt_phone-sticky');
    } else {
      await this.event('kontakt_phone-sticky');
    }
  }

  async clickContactFormContactPersonPhoneNumber(): Promise<void> {
    const eventClick: ContactPersonPhoneNumberEventClick = {
      ...this.getBaseUtagPageViewFields(),
      ...this.getBrokerAddressData(),
      page_name: this.isImmonet() ? '/anbieter/thankyoupage' : '/profil/thankyoupage',
      page_type: 'thankyoupage',
      product_id: ['504'],
      product_name: ['kontakt_anbieter'],
      product_order_id: `${this.isImmonet() ? 'IN' : 'IW'}_${this.getCurrentSeconds()}_${this.getBrokerGlobalUserId()}`,
      product_quantity: [1],
      product_variant: ['phone'],
      event_action: 'click',
      event_category: 'anbieterprofil',
      event_label: 'kontakt_phone-kontaktform'
    };
    const wasSent = await this.context.$universalTag.event(eventClick, 'thankyoupage-phone');
    if (wasSent) {
      this.context.$universalTag.markEventAsSent<EventLabel>('kontakt_phone-kontaktform');
    } else {
      await this.event('kontakt_phone-kontaktform');
    }
  }

  async clickAboutUsTeamContactPersonPhoneNumber(): Promise<void> {
    const eventClick: ContactPersonPhoneNumberEventClick = {
      ...this.getBaseUtagPageViewFields(),
      ...this.getBrokerAddressData(),
      page_name: this.isImmonet() ? '/anbieter/thankyoupage' : '/profil/thankyoupage',
      page_type: 'thankyoupage',
      product_id: ['504'],
      product_name: ['kontakt_anbieter'],
      product_order_id: `${this.isImmonet() ? 'IN' : 'IW'}_${this.getCurrentSeconds()}_${this.getBrokerGlobalUserId()}`,
      product_quantity: [1],
      product_variant: ['phone'],
      event_action: 'click',
      event_category: 'anbieterprofil',
      event_label: 'kontakt_phone-unser team'
    };
    const wasSent = await this.context.$universalTag.event(eventClick, 'thankyoupage-phone');
    if (wasSent) {
      this.context.$universalTag.markEventAsSent<EventLabel>('kontakt_phone-unser team');
    } else {
      await this.event('kontakt_phone-unser team');
    }
  }

  async tabbarEvent(title: TabbarTitle): Promise<void> {
    const tabTitleToTealiumEventLabelMap: { [T in TabbarTitle]: TabNaviEventLabel } = {
      'Über uns': 'tabnavi-Über uns',
      Bewertungen: 'tabnavi-Bewertungen',
      Badges: 'tabnavi-Bewertungen',
      Immobilien: 'tabnavi-Immobilien',
      Team: 'tabnavi-Team',
      Info: 'tabnavi-Info',
      Mitarbeiter: 'tabnavi-Mitarbeiter',
      Objekte: 'tabnavi-Objekte'
    };
    const eventLabel = tabTitleToTealiumEventLabelMap[title];
    if (eventLabel) {
      await this.event(eventLabel);
    }
  }

  async event(
    label: EventLabel,
    // eslint-disable-next-line @typescript-eslint/ban-types
    options: { sendEveryEvent?: boolean } = { sendEveryEvent: false }
  ): Promise<void> {
    await this.context.$universalTag.event<EventClick>(
      {
        event_label: label,
        app_medienid: this.getMedienId(),
        event_action: 'click',
        event_category: 'anbieterprofil'
      },
      label,
      !!options.sendEveryEvent // we generally only track the first occurrence of each event
    );
  }

  async loadMoreObjectsEvent(
    label: EventLabel,
    newEstateGlobalObjectKeys: (string | null)[],
    startIndex: number,
    options: { sendEveryEvent?: boolean } = { sendEveryEvent: false }
  ): Promise<void> {
    await this.context.$universalTag.event<EventClick>(
      {
        ...this.getEnhancedImpressionAndObjectGokFields(newEstateGlobalObjectKeys, startIndex),
        event_label: label,
        app_medienid: this.getMedienId(),
        event_action: 'click',
        event_category: 'anbieterprofil'
      },
      label,
      !!options.sendEveryEvent // we generally only track the first occurrence of each event
    );
  }
}

/**
 * export Plugin
 */
const trackingPlugin: Plugin = (context: Context, inject) => {
  // here we prepare the TEALIUM_URL for the tealium plugin
  // it depends on the tenant, which we initialize using the tenant inspector

  const isImmonet = context.store.getters['page/mediaId'] === MediaIds.Immonet;
  if (isImmonet && context.$config.TEALIUM_URL_IN) {
    context.$config.TEALIUM_URL = context.$config.TEALIUM_URL_IN;
  } else if (!isImmonet && context.$config.TEALIUM_URL_IW) {
    context.$config.TEALIUM_URL = context.$config.TEALIUM_URL_IW;
  }
  const tracking = new BrokerProfileTracking(context);
  inject('track', tracking);
};
export default trackingPlugin;
