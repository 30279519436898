<template>
  <div>
    <p class="error-page__headline">Es tut uns leid.</p>
    <p class="error-page__desc">Dieses Anbieterprofil ist nicht freigeschaltet.</p>
    <sd-btn @click="goHome" v-ripple>Zurück zur Startseite</sd-btn>
  </div>
</template>

<script>
export default {
  name: 'Error422',
  methods: {
    goHome() {
      location.href = location.origin;
    }
  }
};
</script>
