import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { $device, $nuxt, brokerProfileStore } from '~/store';
import {
  BreadCrumb,
  CanonicalTagType,
  FavIconType,
  MediaId,
  Origin,
  ScriptHeader,
  SocialMediaMetaTagType,
  StructuredDataType,
  Tenant
} from '~/types/store';
import { BrokerSettings } from '~/types/types';
import { initAds } from '~/utils/google-publisher-tags';

const MediaID = {
  immoweltAT: 114,
  immoweltDE: 0,
  immonetDE: 195
} as const;

@Module({
  name: 'page',
  namespaced: true,
  stateFactory: true
})
export default class PageStore extends VuexModule {
  _mediaId: MediaId = 0;
  _path: string | undefined = undefined;
  _tld: string | undefined = undefined;
  _farbwelt: string | undefined = undefined;

  get canonicalTags(): CanonicalTagType[] | undefined {
    // nur beim Tenant immowelt und über alles aber nicht adrguid wird das canonical Tag gesetzt
    const adrGuid = brokerProfileStore.basicData?.broker?.adrGuid;

    const [lastsegement, segment] = this._path?.split('/').reverse() ?? [];

    if (!this._path || !adrGuid || !lastsegement || !segment) return [];
    if (this.isImmonet) return [];

    if (lastsegement.startsWith(adrGuid)) {
      return [
        {
          rel: 'canonical',
          href: `${$nuxt.$config.DOMAIN_IW}.${this._tld}/${segment}/${adrGuid}`
        }
      ];
    } else {
      return [
        {
          rel: 'canonical',
          href: `${$nuxt.$config.DOMAIN_IW}.${this._tld}/${segment}/${adrGuid}`
        },
        {
          rel: 'Alternate',
          hreflang: 'de-de',
          href: `${$nuxt.$config.DOMAIN_IW}.de/${segment}/${adrGuid}`
        },
        {
          rel: 'Alternate',
          hreflang: 'de-at',
          href: `${$nuxt.$config.DOMAIN_IW}.at/${segment}/${adrGuid}`
        },
        {
          rel: 'Alternate',
          hreflang: 'x-default',
          href: `${$nuxt.$config.DOMAIN_IW}.de/${segment}/${adrGuid}`
        }
      ];
    }
  }

  get favIcons(): FavIconType[] | undefined {
    if (this.isImmonet) {
      return [
        {
          rel: 'shortcut icon',
          type: 'image/x-icon',
          href: `${$nuxt.$config.DOMAIN_IN}.de/immonet/img/logo/favicon.ico`
        },
        {
          rel: 'icon',
          type: 'image/vnd.microsoft.icon',
          href: `${$nuxt.$config.DOMAIN_IN}.de/favicon.ico`
        },
        {
          rel: 'apple-touch-icon',
          href: `${$nuxt.$config.DOMAIN_IN}.de/immonet/img/logo/apple_touch_icon_48x48.png`
        },
        {
          rel: 'apple-touch-icon',
          sizes: '76x76',
          href: `${$nuxt.$config.DOMAIN_IN}.de/immonet/img/logo/apple_touch-icon-ipad.png`
        },
        {
          rel: 'apple-touch-icon',
          sizes: '120x120',
          href: `${$nuxt.$config.DOMAIN_IN}.de/immonet/img/logo/apple_touch-icon-iphone-retina.png`
        },
        {
          rel: 'apple-touch-icon',
          sizes: '152x152',
          href: `${$nuxt.$config.DOMAIN_IN}.de/immonet/img/logo/apple_touch-icon-ipad-retina.png`
        }
      ];
    }
    return [
      {
        rel: 'shortcut icon',
        type: 'image/x-icon',
        href: '//media-static.immowelt.org/app_themes/global_rwd/image/favicons/favicon.ico'
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '//media-static.immowelt.org/app_themes/global_rwd/image/favicons/apple-touch-icon.png'
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '//media-static.immowelt.org/app_themes/global_rwd/image/favicons/favicon-32x32.png'
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '//media-static.immowelt.org/app_themes/global_rwd/image/favicons/favicon-16x16.png'
      },
      {
        rel: 'mask-icon',
        color: '#FFCC00',
        href: '//media-static.immowelt.org/app_themes/global_rwd/image/favicons/safari-pinned-tab.svg'
      },
      {
        rel: 'manifest',
        href: '//media-static.immowelt.org/app_themes/global_rwd/image/favicons/site.webmanifest'
      }
    ];
  }

  get title(): string {
    if (this.isImmowelt) {
      return `${brokerProfileStore.basicData?.broker?.companyName}, ${brokerProfileStore.basicData?.broker?.city} - Immobilien bei ${this.origin}`;
    } else if (this.isImmonet) {
      return `${brokerProfileStore.basicData?.broker?.companyName} in ${brokerProfileStore.basicData?.broker?.city}, Kontakt & Leistungen bei immonet`;
    }
    return '';
  }

  get description(): { name: string; content: string } {
    if (this.isImmowelt) {
      return {
        name: 'description',
        content: `${brokerProfileStore.basicData?.broker?.companyName} ${brokerProfileStore.basicData?.broker?.city} - aktuelle Immobilienangebote. ${brokerProfileStore.basicData?.broker?.companyName} mit Telefonnummer, Adresse, Ansprechpartnern und Öffnungszeiten.`
      };
    } else if (this.isImmonet) {
      return {
        name: 'description',
        content: `${brokerProfileStore.basicData?.broker?.companyName}. Adresse: ${brokerProfileStore.basicData?.broker?.street}, ${brokerProfileStore.basicData?.broker?.zipCode} ${brokerProfileStore.basicData?.broker?.city}. Weitere Informationen sowie Öffnungszeiten, Leistungen und Angebote finden Sie bei uns.`
      };
    }
    return {
      name: 'description',
      content: ''
    };
  }

  get socialMediaMetaTag(): SocialMediaMetaTagType[] {
    if (!this.isImmonet) {
      return [
        {
          property: 'og:site_name',
          content: `${this.origin} Anbieterprofil`
        },
        {
          property: 'og:url',
          content: `https://www.${this.origin}${this._path}`
        },
        {
          property: 'og:title',
          content: `${brokerProfileStore.basicData?.broker?.companyName}`
        },
        {
          property: 'og:image',
          content:
            brokerProfileStore.brokerMedia?.logo?.url ??
            `https://www.${this.origin}/app_themes/mid_0_rwd/image/logo/og-img_iwde.png`
        },
        {
          name: 'copyright',
          content: 'AVIV Germany GmbH'
        },
        {
          name: 'publisher',
          content: 'AVIV Germany GmbH'
        },
        {
          name: 'company',
          content: 'AVIV Germany GmbH'
        },
        {
          name: 'fb:page_id',
          content: '30229182118'
        },
        {
          property: 'og:type',
          content: 'company'
        },
        {
          property: 'msapplication-TileColor',
          content: '#ffcc00'
        },
        {
          property: 'theme-color',
          content: '#ffc133'
        }
      ];
    } else {
      return [
        {
          property: 'og:site_name',
          content: `${this.origin}`
        },
        {
          property: 'page-topic',
          content: 'Immobilien'
        },
        {
          name: 'google',
          content: 'nositelinkssearchbox'
        },
        {
          name: 'google-translate-customization',
          content: '16a98ff8b6abed92-b98d3f96b969abe3-ged6ca66b530eb2b9-e'
        },
        {
          name: 'verify-v1',
          content: 'E7WpjnwYzDDUsZ4PifavdFRPI+LYgbNJmhDddKe97qw='
        },
        {
          name: 'robots',
          content: 'index, follow'
        },
        {
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'black'
        },
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes'
        },
        {
          name: 'mobile-web-app-capable',
          content: 'yes'
        }
      ];
    }
    return [];
  }

  get structuredSearchEngineLinkingData(): StructuredDataType | undefined {
    const totalReviewCount = brokerProfileStore.brokerValuation?.keyStatistics?.totalReviewCount ?? 0;
    if (!this.isImmonet && totalReviewCount > 0) {
      return {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        name: brokerProfileStore.basicData?.broker?.companyName,
        image: brokerProfileStore.brokerMedia?.logo?.url,
        address: {
          '@type': 'PostalAddress',
          streetAddress: brokerProfileStore.basicData?.broker?.street,
          addressLocality: brokerProfileStore.basicData?.broker?.city,
          postalCode: brokerProfileStore.basicData?.broker?.zipCode
        },
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: `${
            brokerProfileStore.brokerValuation?.keyStatistics?.averageOverallRating?.toString().replace('.', ',') ?? 0
          }`,
          bestRating: '5',
          worstRating: '1',
          ratingCount: `${brokerProfileStore.brokerValuation?.keyStatistics?.totalReviewCount ?? 0}`
        }
      };
    }
    return undefined;
  }

  get structuredBreadcrumbData(): BreadCrumb | undefined {
    if (!this.isImmonet) {
      const geoUrlPath = $nuxt.store.getters['broker/geoUrlPath'];
      let anbieterverzeichnisItem = [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': `https://www.${this.origin}/`,
            name: 'Immobilien'
          }
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': `https://www.${this.origin}/makler/suche`,
            name: 'Makler finden'
          }
        }
      ];

      if (geoUrlPath) {
        anbieterverzeichnisItem = [
          ...anbieterverzeichnisItem,
          {
            '@type': 'ListItem',
            position: 3,
            item: {
              '@id': `https://www.${this.origin}/makler${geoUrlPath}`,
              name: 'Anbieterverzeichnis'
            }
          }
        ];
      }

      return {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: anbieterverzeichnisItem
      };
    }
    return undefined;
  }

  get yieldlove(): ScriptHeader {
    if ($device.isDesktop) {
      return {
        hid: 'yieldlove',
        type: 'text/javascript',
        async: 'async',
        src: `${$nuxt.$config.YIELDLOVE_URL}?${this.origin}`
      };
    }
    return {};
  }

  get gtm(): ScriptHeader {
    return {
      hid: 'gtm',
      type: 'text/javascript',
      innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-5THNWXP');`
    };
  }

  get ads(): ScriptHeader {
    if (!this.isImmonet && $device.isDesktop) {
      return {
        hid: 'ads',
        type: 'text/javascript',
        src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        async: 'async',
        defer: true,
        callback: (): void => {
          window.googletag = window.googletag || {};
          window.googletag.cmd = window.googletag.cmd || [];

          window.googletag.cmd.push(() => {
            const customerType = $nuxt.store.getters['broker/settings'] as undefined | BrokerSettings;
            const globalUserId = $nuxt.store.getters['broker/globalUserId'];
            initAds(
              this._mediaId,
              customerType?.isPartner
                ? {
                    contractType: 'PARTNER',
                    registrationType: 'COMMERCIAL'
                  }
                : undefined,
              globalUserId
            );

            window.googletag.pubads().addEventListener('slotRenderEnded', () => {
              window.document
                .querySelectorAll<HTMLIFrameElement>('iframe[id$=Superbanner_0],iframe[id$=Skyscraper_0]')
                .forEach((iframe) => {
                  iframe.contentDocument?.querySelectorAll('style').forEach((styleElement) => {
                    if (
                      // TODO change for new style tag from ad team
                      /[\s\S]*body[\s\S]*\.anchornav_wrapper[\s\S]*\.expose .anchornav ul li a[\s\S]*\.expose .anchornav ul li a.current[\s\S]*/i.test(
                        styleElement.innerHTML
                      )
                    ) {
                      const colorCode = /body\s+\{\s+border: \d+px.+(#\d+)/.exec(styleElement.innerHTML)?.[1];
                      $nuxt.store.commit('page/setFarbwelt', colorCode);
                    }
                  });
                });
            });

            if (
              !$device.isDesktop &&
              !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
            ) {
              window.googletag.pubads().disableInitialLoad();
            }

            window.googletag.pubads().enableSingleRequest();
            window.googletag.enableServices();
            window.googletag.pubads().refresh();
          });
        }
      };
    }
    return {};
  }

  get tenant(): Tenant {
    return this.isImmonet ? 'immonet' : 'immowelt';
  }

  get origin(): Origin {
    if (this.isImmonet) return 'immonet.de';
    if (this.isImmoweltAt) return 'immowelt.at';
    return 'immowelt.de';
  }

  get mediaId(): MediaId {
    return this._mediaId;
  }

  /**
   * check if is immonet instance
   */
  get isImmonet(): boolean {
    return this._mediaId === MediaID.immonetDE;
  }

  /**
   * check if is immowelt
   */
  get isImmowelt(): boolean {
    return this._mediaId === MediaID.immoweltDE;
  }

  /**
   * check if is immowelt.at
   */
  get isImmoweltAt(): boolean {
    return this._mediaId === MediaID.immoweltAT;
  }

  get farbwelt(): string | undefined {
    return this._farbwelt;
  }

  @Mutation
  public setMediaId(mediaId: MediaId): void {
    this._mediaId = mediaId;
    this._tld = mediaId === MediaID.immoweltAT ? 'at' : 'de';
  }

  @Mutation
  public setCurrentPath(path: string | undefined): void {
    this._path = path;
  }

  @Mutation
  public setFarbwelt(value: string | undefined): void {
    this._farbwelt = value;
  }
}
