import Vue from 'vue';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueSanitize from 'vue-sanitize-directive';

import ripple from '@smartdesign/vue/directives/ripple';
import sdDivider from '@smartdesign/vue/components/divider';
import sdLink from '@smartdesign/vue/components/link';
import sdModal from '@smartdesign/vue/components/modal';
import { SnackbarPlugin } from '@smartdesign/vue/components/snackbar';
import { AlertboxPlugin } from '@smartdesign/vue/components/alertbox';

import { sdButton, sdIconButton } from '@smartdesign/vue/components/button';
import { sdCell, sdCellCol, sdCellRow } from '@smartdesign/vue/components/cell';
import { sdCol, sdContainer, sdRow } from '@smartdesign/vue/components/grid';
import { sdIcon } from '@smartdesign/vue/components/icon';

Vue.use(VueSanitize);

Vue.config.ignoredElements = ['navigation-ui-header', 'navigation-ui-footer', 'noscript', 'feedback-webcomponent'];

Vue.component('SdContainer', sdContainer);
Vue.component('SdCol', sdCol);
Vue.component('SdRow', sdRow);
Vue.component('SdCell', sdCell);
Vue.component('SdCellRow', sdCellRow);
Vue.component('SdCellCol', sdCellCol);
Vue.component('SdIcon', sdIcon);

Vue.component('SdIconBtn', sdIconButton);
Vue.component('SdBtn', sdButton);
Vue.component('SdLink', sdLink);
Vue.component('SdDivider', sdDivider);
Vue.component('SdModal', sdModal);
Vue.directive('ripple', ripple);

interface Snackbar {
  show(options: { text: string; buttonText?: string; onButtonClick?(): void; displayTimeMs?: number }): void;
}

interface Alertbox {
  show(options: {
    query?: 'sd-alertbox-important' | string;
    props: {
      title: string;
      text: string;
      closeButton?: boolean;
      onClose?(): void;
      duration?: number;
      type?: 'error' | 'warning' | 'success' | 'info';
      important?: boolean;
    };
  }): void;
}

declare module 'vue/types/vue' {
  interface Vue {
    $snackbar: Snackbar;
    $alertbox: Alertbox;
  }
}

Vue.use(AlertboxPlugin);
Vue.use(SnackbarPlugin);
