












import { Component, Prop, Vue } from 'vue-property-decorator';
import error404 from '~/components/errors/404.vue';
import error422 from '~/components/errors/422.vue';
import error500 from '~/components/errors/500.vue';
import '@smartdesign/core/css/components/error-page.css';

@Component({
  layout: 'only-header',
  head() {
    return {
      meta: [
        // { 'http-equiv': 'Refresh', name: 'refresh', content: '8; url=https://www.immowelt.de/makler/suche' }
      ]
    };
  }
})
export default class ErrorLayout extends Vue {
  @Prop({ type: Object, default: Object }) readonly error!: any;

  get errorPage() {
    // console.log(this.error.message);
    // console.log(this.error.statusCode);

    if (this.error.message.includes('Unprocessable Entity')) {
      return error422;
    }

    if (this.error.statusCode === 500) {
      return error500;
    }
    return error404;
  }
}
