import { Plugin } from '@nuxt/types';
import { initializeNuxt, initializeAxios, initializeDevices } from '~/utils/accessor';

const accessor: Plugin = (context) => {
  initializeNuxt(context);
  initializeAxios(context.$axios);
  initializeDevices(context.$device);
};

export default accessor;
