import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { $nuxt, $device } from '.';
import { ApplicantPortfolioData, ObjectMemoData } from '~/types/component';

@Module({
  name: 'component',
  namespaced: true,
  stateFactory: true
})
export default class MyModule extends VuexModule {
  showContactModal = false;
  showLegalNotice = false;
  referer: string | undefined = undefined;
  applicantPortfolioData: ApplicantPortfolioData = undefined;
  objectMemoFavouredObjects: ObjectMemoData = undefined;
  private currentlyVisibleEstateObjectCount = 3;
  private currentlyVisibleReferenceObjectCount = 3;

  get userData(): ApplicantPortfolioData {
    return this.applicantPortfolioData;
  }

  get visibleEstateObjectCount(): number {
    return this.currentlyVisibleEstateObjectCount;
  }

  get visibleReferenceObjectCount(): number {
    return this.currentlyVisibleReferenceObjectCount;
  }

  @Mutation
  toggleShowModal(): void {
    this.showContactModal = !this.showContactModal;
  }

  @Mutation
  toggleLegalNotice(): void {
    this.showLegalNotice = !this.showLegalNotice;
  }

  @Mutation
  public setReferer(referer: string | undefined): void {
    this.referer = referer;
  }

  @Mutation
  public setApplicantPortfolioData(data: ApplicantPortfolioData): void {
    this.applicantPortfolioData = data;
  }

  @Mutation
  private setObjectMemoFavouredObjects(data: ObjectMemoData): void {
    this.objectMemoFavouredObjects = data;
  }

  @Mutation
  showMoreEstateObjects(options?: { initial?: boolean }): void {
    if (options?.initial) {
      this.currentlyVisibleEstateObjectCount = $device.isDesktop ? 6 : 3;
    } else {
      this.currentlyVisibleEstateObjectCount += $device.isDesktop ? 6 : 3;
    }
  }

  @Mutation
  showMoreReferenceObjects(options?: { initial?: boolean }): void {
    if (options?.initial) {
      this.currentlyVisibleReferenceObjectCount = $device.isDesktop ? 6 : 3;
    } else {
      this.currentlyVisibleReferenceObjectCount += $device.isDesktop ? 6 : 3;
    }
  }

  @Action
  public async fetchObjectMemoFavouredObjects(): Promise<void> {
    const objectMemoServiceUrl = `${$nuxt.$config.PUBLIC_API_GATEWAY_URL}${$nuxt.$config.OBJECT_MEMO_SERVICE_URL_PATH}objectmemos/default/entries?excludeImmoObjectData=true`;
    const bearerToken = $nuxt.store.getters['auth/bearerToken'];

    try {
      $nuxt.$axios.setHeader('Authorization', bearerToken);
      const result = await $nuxt.$axios.get<ObjectMemoData>(objectMemoServiceUrl);

      if (result.status === 200) {
        this.setObjectMemoFavouredObjects(result.data);
      }
    } catch (err: unknown) {
      const error = err as Error;
      // eslint-disable-next-line no-console
      console.error('[objectmemo fetch error]', error?.message ?? error);
    }
  }

  @Action
  public async removeObjectFromFavouredList(globalObjectKey: string): Promise<void> {
    const objectMemoServiceUrl = `${$nuxt.$config.PUBLIC_API_GATEWAY_URL}${$nuxt.$config.OBJECT_MEMO_SERVICE_URL_PATH}objectmemos/default/entries/${globalObjectKey}`;
    const bearerToken = $nuxt.store.getters['auth/bearerToken'];

    try {
      const result = await $nuxt.$axios.delete<ObjectMemoData>(objectMemoServiceUrl, {
        headers: { Authorization: bearerToken }
      });

      if (result?.status && result.status !== 204) {
        // eslint-disable-next-line no-console
        console.warn('[objectmemo remove error] An error occured while removing an object from the objectmemoservice');
      }
    } catch (err: unknown) {
      const error = err as Error;
      // eslint-disable-next-line no-console
      console.error('[objectmemo remove error]', error?.message ?? error);
    }
  }

  @Action
  public async addObjectToFavouredList(globalObjectKey: string): Promise<void> {
    const objectMemoServiceUrl = `${$nuxt.$config.PUBLIC_API_GATEWAY_URL}${$nuxt.$config.OBJECT_MEMO_SERVICE_URL_PATH}objectmemos/default/entries`;
    const bearerToken = $nuxt.store.getters['auth/bearerToken'];
    const globalUserId = $nuxt.store.getters['auth/globalUserId'];
    const content = JSON.stringify({
      addedDate: new Date().toISOString(),
      objectKey: globalObjectKey,
      userId: globalUserId
    });

    try {
      $nuxt.$axios.setHeader('Authorization', bearerToken);
      $nuxt.$axios.setHeader('Content-Type', 'application/json');
      const result = await $nuxt.$axios.put<ObjectMemoData>(objectMemoServiceUrl, content);

      if (result?.status && result.status !== 204) {
        // eslint-disable-next-line no-console
        console.warn('[objectmemo add error] An error occured while adding an object from the objectmemoservice');
      }
    } catch (err: unknown) {
      const error = err as Error;
      // eslint-disable-next-line no-console
      console.error('[objectmemo add error]', error?.message ?? error);
    }
  }
}
