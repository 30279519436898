import { ComponentOptions } from 'vue';
import { Vue, Component } from 'nuxt-property-decorator';

@Component({
  /**
   * components
   */
  components: {}
} as ComponentOptions<Header>)
export default class Header extends Vue {}
