/* eslint-disable @typescript-eslint/ban-types */
import { Context, Plugin } from '@nuxt/types';
import { MetaInfo, ScriptPropertySrcCallback } from 'vue-meta';

export type UtagData = Record<string, string | number | boolean | Array<string | number | boolean>>;

interface utagWindow extends Window {
  utag: {
    view(pageView: object): unknown;
    link(eventClick: object): unknown;
    data: UtagData;
  };
  // eslint-disable-next-line camelcase
  utag_cfg_ovrd: {
    noview?: boolean;
  };
}

declare let window: utagWindow;
declare module 'vue/types/vue' {
  interface Vue {
    $universalTag: UniversalTag;
  }
}

declare module '@nuxt/types' {
  export interface Context {
    $universalTag: UniversalTag;
  }
}

declare module '@nuxt/types/app' {
  interface NuxtAppOptions {
    $universalTag: UniversalTag;
  }
}

export class UniversalTag {
  /** promise resolver to mark initialization */
  private resolveInitialization?: () => void;
  /** promise which marks if utag is initialized */
  private $initialized: Promise<void> = new Promise((resolve) => (this.resolveInitialization = resolve));

  private sentEventKeys: string[] = [];
  private sentPageViewKeys: string[] = [];

  /** marks utag as initialized */
  finalizeInitialization(): void {
    if (!window.utag) {
      return;
    }
    this.resolveInitialization?.();
  }

  markEventAsSent<Label extends string>(eventLabel: Label): void {
    if (!this.sentEventKeys.includes(eventLabel)) {
      this.sentEventKeys.push(eventLabel);
    }
  }

  constructor(private readonly context: Context) {}

  /** First call of view or event will load the plugin */
  private initialization(): Promise<void> {
    return this.$initialized;
  }

  async event<E extends object>(eventClick: E, eventId: string, sendEveryEvent = false): Promise<boolean> {
    await this.initialization();
    if (!sendEveryEvent && this.sentEventKeys.includes(eventId)) {
      return false;
    }
    if (!sendEveryEvent) {
      this.sentEventKeys = [...this.sentEventKeys, eventId];
    }
    window.utag.link({ ...eventClick });
    return true;
  }

  async view<E extends object>(pageView: E, pageViewId: string, sendEveryView = false): Promise<boolean> {
    await this.initialization();
    if (!sendEveryView && this.sentPageViewKeys.includes(pageViewId)) {
      return false;
    }
    if (!sendEveryView) {
      this.sentPageViewKeys = [...this.sentPageViewKeys, pageViewId];
    }
    window.utag.view({ ...pageView });
    return true;
  }
}

/**
 * export Plugin
 * @param app
 * @param store
 * @param inject
 */
const utagPlugin: Plugin = (context, inject) => {
  // tealium config for spa
  // https://docs.tealium.com/platforms/javascript/single-page-applications/
  if (process.client) {
    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
    window.utag_cfg_ovrd.noview = true;
    // debugging
    // document.cookie = 'utagdb=true';
  }

  const universalTagInjectee = new UniversalTag(context);

  const head = context.app.head as MetaInfo;
  if (!head || !head.script || !context.$config.TEALIUM_URL) {
    throw new Error('Cannot initialize UTag');
  }
  const headScriptTag: ScriptPropertySrcCallback = {
    vmid: 'utag-tealium-script',
    src: context.$config.TEALIUM_URL,
    hid: 'tealium',
    callback: () => universalTagInjectee.finalizeInitialization(),
    async: true
  };
  head.script.push(headScriptTag);

  inject('universalTag', universalTagInjectee);
};
export default utagPlugin;
